import React from "react"
import { graphql } from 'gatsby'

import * as styles from './events.module.css'
import EventInformation from "../components/event-information";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

export default function HomePage({ data }) {

  const upcomingEvent = data.allContentfulTeDxKi.edges[0].node.upcomingEvent;
  return (
      <div class="text-center">
        <EventInformation event={upcomingEvent}></EventInformation>
        <h2>PAST EVENTS</h2>
        <Container fluid>
          <Row lg={2} xs={1}>
            {
              data.allContentfulTeDxKi.edges[0].node.pastEvents.map(event => {
                return (
                  <Col key={event.id} className="mt-3">
                    <Card>
                      <Card.Img variant="top" src={event.image.file.url} className={styles.past_event_photo}/>
                      <Card.Body>
                        <Card.Title><strong>{event.name}</strong></Card.Title>
                        <a href={"/event/" + event.yearIdentifier} class="stretched-link"></a>
                      </Card.Body>
                    </Card>
                  </Col>
                )
              })
            }
          </Row>
        </Container>
      </div>
  );
};

export const query = graphql`
  query {
    allContentfulTeDxKi {
      edges {
        node {
          upcomingEvent {
            speakers {
              photo {
                fluid(maxWidth: 317) {
                  ...GatsbyContentfulFluid
                }
              }
              jobTitle
              name
              bio {
              childMarkdownRemark {
                internal {
                    content
                  }
                }
              }
              linkedInProfileLink
            }
            performers {
              photo {
              fluid(maxWidth: 317) {
                  ...GatsbyContentfulFluid
                }
              }
              name
              bio {
              childMarkdownRemark {
                internal {
                    content
                  }
                }
              }
              title
            }
            startTime
            endTime
            description {
              childMarkdownRemark {
                html
              }
            }
            name
            yearIdentifier
            image {
              file {
                url
              }
              fluid(maxWidth: 2500, quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
            location
            relatedVideos {
              name
              linkToVideo
            }
          }
          pastEvents {
            name
            yearIdentifier
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
